<template>
    <transition name="fade" appear>
        <div class="author">
            <div class="author__header">
                <div class="author__header-left">
                    <p>{{ getName }}</p>
                    <span>{{ dateOutput.local().format('HH:mm DD.MM.YYYY') }}</span>
                </div>
                <button
                    v-if="author.status !== 2"
                    type="button"
                    class="btn-block-option close-modal close-button"
                    @click="onClick(author, 2)"
                >
                    ×
                </button>
            </div>
            <div class="author__body">
                <p>
                    {{
                        author.text ||
                            "Материал устарел"
                    }}
                </p>
            </div>
            <div class="author__bottom">
                <button
                    v-if="author.status !== 1"
                    class="v-btn v-btn--yellow"
                    @click="onClick(author, 1)"
                >
                    <span
                        ><svg
                            width="15"
                            height="13"
                            viewBox="0 0 15 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 6.32895L5.30519 11L14 1"
                                stroke="black"
                                stroke-width="2"
                            />
                        </svg>
                        Решено</span
                    >
                </button>
                <button
                    v-if="author.status !== 0"
                    class="v-btn v-btn--transparent button"
                    @click="onClick(author, 0)"
                >
                    <span
                        ><svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.45988 8.16068L1.52237 4.79812L4.45988 1.43555C4.69725 1.16352 4.66956 0.750428 4.39753 0.512834C4.1255 0.275459 3.71263 0.303365 3.47481 0.575177L0.161531 4.36802C-0.0538438 4.61433 -0.0538438 4.98208 0.161531 5.2284L3.47484 9.02127C3.60431 9.16905 3.78547 9.24493 3.96769 9.24493C4.12028 9.24493 4.2735 9.19174 4.39753 9.0834C4.66959 8.8458 4.69747 8.43271 4.45988 8.16068Z"
                                fill="currentColor"
                            />
                            <path
                                d="M11.2478 4.14404H0.653938C0.29275 4.14404 0 4.43676 0 4.79798C0 5.15917 0.29275 5.45192 0.653938 5.45192H11.2478C13.1471 5.45192 14.6921 6.99695 14.6921 8.89601C14.6921 10.7951 13.1471 12.3401 11.2478 12.3401H1.52587C1.16469 12.3401 0.871938 12.6329 0.871938 12.994C0.871938 13.3552 1.16469 13.648 1.52587 13.648H11.2478C13.8679 13.648 16 11.5161 16 8.89601C16 6.27589 13.8682 4.14404 11.2478 4.14404Z"
                                fill="currentColor"
                            />
                        </svg>

                        Вернуть в актуальные</span
                    >
                </button>
            </div>
            <hr />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ShowCommentAuthor',
    props: ['author'],
    data() {
        return {
            data: {},
            dateOutput: this.$moment.utc(this.author.created)
        }
    },
    mounted() {
        const statusDate = this.$moment.utc(this.author.created).local().valueOf() > new Date().getTime()
        this.dateOutput = statusDate ? this.$moment(this.author.created) : this.dateOutput
    },
    computed: {
        getName() {
            return this.author?.author?.full_name || 'Сотрудник'
        }
    },
    methods: {
        onClick(data, status) {
            this.data = { id: data.id, status, old_status: this.author.status }
            this.$emit('fetch-status', this.data)
        }
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,.fade-leave-active {
    transition: all 300ms;
    max-height: 170px;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
    max-height: 0;
}

.close-button {
    position: static;
}
.button {
    padding: 0;
}
.author {
    display: flex;
    flex-direction: column;
    color: #000;
    & > hr {
        margin: 0;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        &-left {
            display: flex;
            align-items: center;
            line-height: 40px;
            & > p {
                font-weight: 600;
                font-size: 16px;
                margin: 0;
            }
            & > span {
                font-weight: normal;
                font-size: 14px;
                color: #989cae;
                margin-left: 12px;
            }
        }
    }
    &__body {
        display: flex;
        margin-bottom: 24px;
        & > p {
            font-size: 16px;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
        }
    }
    &__bottom {
        display: flex;
        margin-bottom: 36px;

        & > * + * {
            margin-left: 20px;
        }
        & > button {
            display: flex;
            align-items: center;
            & > span {
                display: flex;
                align-items: center;
            }
            & svg {
                margin-right: 8px;
            }
        }
    }
}
</style>
